<template>
  <div class="content">
    <div class="top_bg" style="background-image: url('img/qixi/top_bg.png')">
      <div
        class="my_cp"
        style="background-image: url('img/qixi/my_cp.png')"
        @click="toMyCp"
      ></div>
      <div
        class="cp_wall"
        style="background-image: url('img/qixi/cp_wall.png')"
        @click="toCpWall"
      ></div>
      <div
        class="rule"
        style="background-image: url('img/qixi/rule.png')"
        @click="toRule"
      ></div>
      <div
        class="gift"
        style="background-image: url('img/qixi/gift.png')"
        @click="toGift"
      ></div>
    </div>
    <div
      class="active_text"
      style="background-image: url('img/qixi/active_text.png')"
    ></div>
    <div
      class="bind_gift"
      style="background-image: url('img/qixi/bind_gift.png')"
    >
      <div style="margin-top: 20px">
        <div
          class="bind_item"
          style="background-image: url('img/qixi/item_bg.png')"
        >
          <img class="gift_img" src="https://fwgift.oss-cn-hangzhou.aliyuncs.com/qixi_gift/lianlizhi.png" alt="" />
          <div class="gift_name">连理枝</div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 8px;font-size: 14px;color: #ffff;">
          <span>52</span>
          <img class="bi" src="img/bi.png" alt="" />
        </div>
      </div>
    </div>
    <div class="xd_gift" style="background-image: url('img/qixi/xd_gift.png')">
      <div style="margin-top: 20px">
        <div
          class="bind_item"
          style="background-image: url('img/qixi/item_bg.png')"
        >
          <img class="gift_img" src="https://fwgift.oss-cn-hangzhou.aliyuncs.com/qixi_gift/xunaibi.png" alt="" />
          <div class="gift_name">寻爱笔</div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 8px;font-size: 14px;color: #ffff;">
          <span>520</span>
          <img class="bi" src="img/bi.png" alt="" />
        </div>
      </div>
      <div style="margin-top: 20px">
        <div
          class="bind_item"
          style="background-image: url('img/qixi/item_bg.png')"
        >
          <img class="gift_img" src="https://fwgift.oss-cn-hangzhou.aliyuncs.com/qixi_gift/huaxizhijin.png" alt="" />
          <div class="gift_name">花夕之境</div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 8px;font-size: 14px;color: #ffff;">
          <span>1314</span>
          <img class="bi" src="img/bi.png" alt="" />
        </div>
      </div>
      <div style="margin-top: 20px">
        <div
          class="bind_item"
          style="background-image: url('img/qixi/item_bg.png')"
        >
          <img class="gift_img" src="https://fwgift.oss-cn-hangzhou.aliyuncs.com/qixi_gift/diemenghuaqiao.png" alt="" />
          <div class="gift_name">蝶梦花桥</div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 8px;font-size: 14px;color: #ffff;">
          <span>5200</span>
          <img class="bi" src="img/bi.png" alt="" />
        </div>
      </div>
      <div style="margin-top: 20px">
        <div
          class="bind_item"
          style="background-image: url('img/qixi/item_bg.png')"
        >
          <img class="gift_img" src="https://fwgift.oss-cn-hangzhou.aliyuncs.com/qixi_gift/xinghezhilian.png" alt="" />
          <div class="gift_name">星河之约</div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 8px;font-size: 14px;color: #ffff;">
          <span>13140</span>
          <img class="bi" src="img/bi.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="rank_list"
      style="background-image: url('img/qixi/rank_bg.png')"
    >
      <div class="nav">
        <div
          v-for="(item, index) in list"
          :style="
            cur_nav == index ? 'background-image:url(' + item.ac_bg + ')' : ''
          "
          :key="index"
          class="btn_"
          @click="changeNav(index)"
        >
          {{ cur_nav != index ? item.name : "" }}
        </div>
      </div>
      <!-- top_three开始 -->
      <div class="top_three">
        <div class="two" style="background-image: url('img/qixi/two.png')" v-if="listData[1]">
          <div class="head_box" v-if="cur_nav == 0">
            <div
              class="head_item left"
              style="background-image: url('img/qixi/head_two.png')"
            >
              <img class="left_img" :src="listData[1].a_head_portrait" alt="" />
            </div>
            <div
              class="head_item right"
              style="background-image: url('img/qixi/head_two.png')"
            >
              <img
                class="right_img"
                :src="listData[1].b_head_portrait"
                alt=""
              />
            </div>
          </div>
          <div class="head_box" v-else>
            <div
              class="head_item center"
              style="background-image: url('img/qixi/head_two.png')"
            >
              <img class="h_img" :src="listData[1].head_portrait" alt="" />
            </div>
          </div>
          <div v-if="cur_nav == 0" class="name_box">
            {{ listData[1].a_nickname + "&" + listData[1].b_nickname }}
          </div>
          <div v-else class="name_box">{{ listData[1].nickname }}</div>
          <div class="name_box">甜蜜值</div>
          <div class="value">
            {{
              cur_nav == 0
                ? listData[1].inr_value
                : listData[1].total_charm_value
            }}
          </div>
        </div>
        <div class="one" style="background-image: url('img/qixi/one.png')" v-if="listData[0]">
          <div class="head_box" v-if="cur_nav == 0">
            <div
              class="head_item left"
              style="background-image: url('img/qixi/head_one.png')"
            >
              <img class="left_img" :src="listData[0].a_head_portrait" alt="" />
            </div>
            <div
              class="head_item right"
              style="background-image: url('img/qixi/head_one.png')"
            >
              <img
                class="right_img"
                :src="listData[0].b_head_portrait"
                alt=""
              />
            </div>
          </div>
          <div class="head_box" v-else>
            <div
              class="head_item center"
              style="background-image: url('img/qixi/head_one.png')"
            >
              <img class="h_img" :src="listData[0].head_portrait" alt="" />
            </div>
          </div>
          <div v-if="cur_nav == 0" class="name_box">
            {{ listData[0].a_nickname + "&" + listData[0].b_nickname }}
          </div>
          <div v-else class="name_box">{{ listData[0].nickname }}</div>
          <div class="name_box">甜蜜值</div>
          <div class="value">
            {{
              cur_nav == 0
                ? listData[0].inr_value
                : listData[0].total_charm_value
            }}
          </div>
        </div>
        <div
          class="three"
          style="background-image: url('img/qixi/three.png')"
          v-if="listData[2]"
        >
          <div class="head_box" v-if="cur_nav == 0">
            <div
              class="head_item left"
              style="background-image: url('img/qixi/head_three.png')"
            >
              <img class="left_img" :src="listData[2].a_head_portrait" alt="" />
            </div>
            <div
              class="head_item right"
              style="background-image: url('img/qixi/head_three.png')"
            >
              <img
                class="right_img"
                :src="listData[2].b_head_portrait"
                alt=""
              />
            </div>
          </div>
          <div class="head_box" v-else>
            <div
              class="head_item center"
              style="background-image: url('img/qixi/head_three.png')"
            >
              <img class="h_img" :src="listData[2].head_portrait" alt="" />
            </div>
          </div>
          <div v-if="cur_nav == 0" class="name_box">
            {{ listData[2].a_nickname + "&" + listData[2].b_nickname }}
          </div>
          <div v-else class="name_box">{{ listData[2].nickname }}</div>
          <div class="name_box">甜蜜值</div>
          <div class="value">
            {{
              cur_nav == 0
                ? listData[2].inr_value
                : listData[2].total_charm_value
            }}
          </div>
        </div>
      </div>
      <!-- top_three结束 -->
      <div class="list_box" v-if="listOther.length > 0">
        <div class="list_item" v-for="(item, index) in listOther" :key="index">
          <div style="display: flex">
            <div class="index">{{ index + 4 }}</div>
            <div class="heads_box" v-if="cur_nav == 0">
              <img
                class="list_img img_left"
                :src="item.a_head_portrait"
                alt=""
              />
              <img
                class="list_img img_right"
                :src="item.b_head_portrait"
                alt=""
              />
            </div>
            <div v-else>
              <img class="list_img" :src="item.head_portrait" alt="" />
            </div>
            <div class="nickname__" v-if="cur_nav == 0">
              {{ item.a_nickname + "&" + item.b_nickname }}
            </div>
            <div v-else>
              <div class="nickname_">{{ item.nickname }}</div>
              <div class="nickname_">ID:{{ item.uid }}</div>
            </div>
          </div>
          <div>
            <div class="name_box">甜蜜值</div>
            <div class="value">
              {{ cur_nav == 0 ? item.inr_value : item.total_charm_value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bottom_text"
      style="background-image: url('img/qixi/bottom_text.png')"
    ></div>
    <div class="list_item_" v-if="isUp != 1">
      <div style="display: flex">
        <div class="index">10+</div>
        <!-- <div class="heads_box" v-if="cur_nav==0">
              <img class="list_img img_left" :src="item.a_head_portrait" alt="">
              <img class="list_img img_right" :src="item.B_head_portrait" alt="">
            </div> -->
        <div>
          <img class="list_img" :src="myHead" alt="" />
        </div>
        <!-- <div class="nickname__" v-if="cur_nav==0">{{item.a_nickname+'&'+item.b_nickname}}</div> -->
        <div>
          <div class="nickname__">{{ nickname }}</div>
          <!-- <div class="nickname_">ID:{{item.uid}}</div> -->
        </div>
      </div>
      <div>
        <div class="name_box">甜蜜值</div>
        <div class="value">{{ myValue }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/qixi.css";
import { guardRank } from "@/api/ranking";
export default {
  data() {
    return {
      list: [
        { name: "情缘榜", ac_bg: "img/qixi/qy_active.png" },
        { name: "守护榜", ac_bg: "img/qixi/sh_active.png" },
        { name: "宠爱榜", ac_bg: "img/qixi/ca_active.png" },
      ],
      cur_nav: 0,
      resData: {
        page: 1,
        rows: 15,
        type: 1,
      },
      nickname: "",
      myHead: "",
      myValue: "",
      isUp: "",
      listData: [],
      listOther: [],
    };
  },
  created() {
    let access_token = this.$route.query.access_token;
    localStorage.setItem("access_token", access_token);
    this.getdataList();
  },
  methods: {
    changeNav(index) {
      this.cur_nav = index;
      this.getdataList();
    },
    getdataList() {
      this.resData.type = this.cur_nav + 1;
      guardRank(this.resData).then((res) => {
        this.myHead = res.data.my_head_portrait;
        this.nickname = res.data.my_nickname;
        this.isUp = res.data.is_up;
        this.myValue = res.data.value;
        this.listData = res.data.list;
        if (res.data.list.length > 3) {
          this.listOther = res.data.list.slice(3);
        } else {
          this.listOther = [];
        }
      });
    },
    toRule() {
      this.$router.push({ path: "/qixi_rule", query: {} });
    },
    toGift() {
      this.$router.push({ path: "/qixiGift", query: {} });
    },
    toMyCp() {
      this.$router.push({ path: "/my_cp", query: {} });
    },
    toCpWall() {
      this.$router.push({ path: "/cp_wall", query: {} });
    },
  },
};
</script>
